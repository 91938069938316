import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Background from '../images/landing-page.jpg';
import { Seo } from '../components/seo';

const LandingPage = ({ data }) => {
  return (
    <Layout
      navBarLink="navBarLinkLandingPage"
      navBarLinkHome="navBarLinkHome"
      navBarLinkWork="navBarLinkWorkLandingPage"
      bgImage={Background}
    >
      <Seo title="Home" />

      <></>
    </Layout>
  );
};

export default LandingPage;

export const pageQuery = graphql`
  query HomeQuery {
    background: allFile(filter: { name: { eq: "landing-page" } }) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    work: allContentfulWork {
      nodes {
        id
        title
        type
        slug
        visuals {
          gatsbyImageData
        }
      }
    }
  }
`;
